import { useEffect } from "react";
export const usePendo = (user, venue) => {
    useEffect(() => {
        var _a;
        if (user) {
            const { email, id, roles, properties, accountId, accountName } = user;
            const fullName = (properties === null || properties === void 0 ? void 0 : properties.firstName) && properties.lastName
                ? `${properties.firstName} ${properties.lastName}`
                : "";
            const venueId = properties === null || properties === void 0 ? void 0 : properties.selectedVenueId;
            (_a = window.pendo) === null || _a === void 0 ? void 0 : _a.initialize({
                visitor: {
                    email: email,
                    id: id,
                    fullName: fullName,
                    firstName: (properties === null || properties === void 0 ? void 0 : properties.firstName) || "",
                    language: (properties === null || properties === void 0 ? void 0 : properties.locale) || "",
                    role: roles[0] || "",
                    venueId: venueId || "",
                    venueName: (venue === null || venue === void 0 ? void 0 : venue.name) || "",
                },
                account: {
                    id: accountId,
                    name: accountName,
                },
            });
        }
    }, [user, venue]);
};
