var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from "react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useAppDispatch } from "../store/hooks";
import { updateFeatureFlag } from "../store/services/featureFlag/actions";
import toUserContext from "../utils/toUserContext";
const useLaunchDarkly = (userData) => {
    const ldClient = useLDClient();
    const flags = useFlags();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(updateFeatureFlag(Object.assign({}, flags)));
    }, [flags]);
    if (!ldClient)
        return {};
    useEffect(() => {
        const updateLaunchDarklyContext = () => __awaiter(void 0, void 0, void 0, function* () {
            yield ldClient.identify(toUserContext(userData));
        });
        void updateLaunchDarklyContext();
    }, [userData]);
    return { flags };
};
export default useLaunchDarkly;
