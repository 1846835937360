import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { aiakosBaseQuery } from "../../client/AiakosClient";
// Validates whether an auth token is valid
export const authService = createApi({
    reducerPath: "authService",
    baseQuery: fetchBaseQuery(aiakosBaseQuery),
    tagTypes: ["Auth"],
    endpoints: (builder) => ({
        checkAuthentication: builder.query({
            query: (data) => data.verifyTokenUri,
            transformResponse: (_response, meta, _arg) => {
                var _a;
                if (((_a = meta === null || meta === void 0 ? void 0 : meta.response) === null || _a === void 0 ? void 0 : _a.status) === 200) {
                    return true;
                }
                return false;
            },
            providesTags: [{ type: "Auth" }],
        }),
    }),
});
export const { useCheckAuthenticationQuery, useLazyCheckAuthenticationQuery } = authService;
