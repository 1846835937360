import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { aiakosBaseQuery } from "../../client/AiakosClient";
// Fetches user info from Aiakos. Including user's name, email, account name etc
export const userInfoService = createApi({
    reducerPath: "userInfoService",
    baseQuery: fetchBaseQuery(aiakosBaseQuery),
    tagTypes: ["UserInfo"],
    endpoints: (builder) => ({
        getUserInfo: builder.query({
            query: (data) => data.userInfoUri,
            providesTags: [{ type: "UserInfo" }],
        }),
    }),
});
export const { useGetUserInfoQuery } = userInfoService;
