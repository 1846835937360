import { useLocation } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppSelector } from "../store";
import { useGetConfigQuery } from "../store/services/config";
import { selectFeatureFlags } from "../store/services/featureFlag/selectors";
import { useGetModulesQuery } from "../store/services/modules";
import { constructLinks, getActiveLink } from "../utils/links";
export const useModules = (user, account) => {
    var _a;
    const { data: config } = useGetConfigQuery();
    const rootPath = config === null || config === void 0 ? void 0 : config.rootPath;
    const { pathname } = useLocation();
    const allModuleAccess = (_a = account === null || account === void 0 ? void 0 : account.properties) === null || _a === void 0 ? void 0 : _a.allModuleAccess;
    const accountId = account === null || account === void 0 ? void 0 : account.id;
    const featureFlags = useAppSelector(selectFeatureFlags);
    const { data: modules, isLoading: isLoadingModules, isError: isModulesError, } = useGetModulesQuery(user ? { userId: user.id } : skipToken);
    const links = constructLinks(featureFlags, modules, user, accountId, allModuleAccess, rootPath);
    const activeLink = getActiveLink(links, pathname, rootPath);
    return {
        links,
        activeLink,
        hasLinks: !isLoadingModules && !!modules && !!user,
        isModulesError,
    };
};
