function toUserContext(userData) {
    if (!userData) {
        return { kind: "user", anonymous: true };
    }
    return {
        kind: "user",
        key: userData.id.toString(),
        email: userData.email,
        roles: userData.roles,
        accountId: userData.accountId,
    };
}
export default toUserContext;
