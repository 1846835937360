import { datadogLogs, HandlerType, StatusType } from "@datadog/browser-logs";
import isDev from "../isDev";
import isTest from "../isTest";
import ignoreMessage from "./ignoreMessage";
let logger;
if (!isTest()) {
    datadogLogs.onReady(function () {
        logger = datadogLogs.logger;
        const handlers = [HandlerType.http];
        if (isDev()) {
            // We always want to see them for local development
            handlers.push(HandlerType.console);
        }
        logger.setHandler(handlers);
        // Log everything, from lowest level
        logger.setLevel(StatusType.debug);
    });
}
// This ensures we always have an error object to pass on further if possible
function downcastErrorFromMessage(message, originalError) {
    if (!originalError && message instanceof Error) {
        return message;
    }
    else if (originalError instanceof Error) {
        return originalError;
    }
    return undefined;
}
const debug = (message, messageContext, originalError) => {
    if (ignoreMessage(message))
        return;
    const error = downcastErrorFromMessage(message, originalError);
    logger === null || logger === void 0 ? void 0 : logger.debug(String(message), messageContext, error);
};
const info = (message, messageContext, originalError) => {
    if (ignoreMessage(message))
        return;
    const error = downcastErrorFromMessage(message, originalError);
    logger === null || logger === void 0 ? void 0 : logger.info(String(message), messageContext, error);
};
const warn = (message, messageContext, originalError) => {
    if (ignoreMessage(message))
        return;
    const error = downcastErrorFromMessage(message, originalError);
    logger === null || logger === void 0 ? void 0 : logger.warn(String(message), messageContext, error);
};
const error = (message, messageContext, originalError) => {
    if (ignoreMessage(message))
        return;
    const error = downcastErrorFromMessage(message, originalError);
    logger === null || logger === void 0 ? void 0 : logger.error(String(message), messageContext, error);
};
export default {
    debug,
    info,
    warn,
    error,
};
