import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../client/DefaultClient";
export const userService = createApi({
    reducerPath: "userService",
    baseQuery: fetchBaseQuery(baseQuery),
    tagTypes: ["User"],
    endpoints: (builder) => ({
        getUser: builder.query({
            query: ({ id }) => `api/identity/users/${id}`,
            providesTags: (user) => [{ type: "User", id: user === null || user === void 0 ? void 0 : user.id }],
        }),
    }),
});
export const { useGetUserQuery } = userService;
