import { useState } from "react";
import { DIALOG_REMOVED, DIALOG_VISIBLE, navigationChannel, } from "../utils/broadcastChannel";
// Handle dialog showing in iframe
export const useDialogListener = () => {
    const [dialogVisible, setDialogVisible] = useState(false);
    navigationChannel.addEventListener("message", (event) => {
        if (event.data === DIALOG_VISIBLE) {
            setDialogVisible(true);
        }
        else if (event.data === DIALOG_REMOVED) {
            setDialogVisible(false);
        }
    });
    // TODO: Remove this once all apps are using broadcast channel
    window.addEventListener("message", (event) => {
        if (event.origin.includes("dexibit.io") || event.origin.includes("dexibit.com")) {
            const message = event.data;
            if (message === DIALOG_VISIBLE) {
                setDialogVisible(true);
            }
            else if (message === DIALOG_REMOVED) {
                setDialogVisible(false);
            }
        }
    });
    return { dialogVisible };
};
