import { PALETTE_LIGHT, SPACING, styled, UNIT } from "@dexibit/styles";
export const StyledNav = styled("nav") `
  min-width: 80px;
  height: 100vh;
  background-color: ${PALETTE_LIGHT.SURFACE_BASE};
  border-right: 1px solid ${PALETTE_LIGHT.SURFACE_BORDER};
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const NavContent = styled("div") `
  display: flex;
  flex-direction: column;
`;
export const NavLinksList = styled("ul") `
  list-style: none;
  margin: 0;
  padding: 0;
`;
export const LogoContainer = styled("div") `
  margin: ${SPACING.large} ${SPACING.small};
  box-sizing: content-box;
  display: flex;
  align-items: center;
  height: ${UNIT.xxlarge};
`;
export const StyledOverlay = styled("div") `
  width: 81px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  //background-color: ${PALETTE_LIGHT.BACKGROUND_DIALOG};
  z-index: 5;
  position: absolute;
`;
