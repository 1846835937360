import { Environ, NodeEnvType } from "./types";
const getEnv = () => {
    if (process.env.NODE_ENV === NodeEnvType.TEST.toString()) {
        return Environ.TEST;
    }
    if (ENVIRON)
        return ENVIRON;
    return undefined;
};
export default getEnv;
