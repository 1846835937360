import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../client/DefaultClient";
export const accountService = createApi({
    reducerPath: "accountService",
    baseQuery: fetchBaseQuery(baseQuery),
    tagTypes: ["Account"],
    endpoints: (builder) => ({
        getAccount: builder.query({
            query: ({ id }) => `api/identity/accounts/${id}`,
            providesTags: (account) => [{ type: "Account", id: account === null || account === void 0 ? void 0 : account.id }],
        }),
    }),
});
export const { useGetAccountQuery } = accountService;
