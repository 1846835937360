import tokenManager from "../../utils/tokenManager";
export const aiakosBaseQuery = {
    baseUrl: "/",
    prepareHeaders: (headers, { endpoint }) => {
        const jwt = tokenManager.getToken();
        if (jwt) {
            headers.set("authorization", `Bearer ${jwt}`);
        }
        // If endpoint is checkAuthentication then change content type.
        // This could get a bit messy if we do this a lot but unlikely
        if (endpoint === "checkAuthentication") {
            headers.set("content-type", "application/x-www-form-urlencoded");
        }
        else {
            headers.set("content-type", "application/json");
        }
        return headers;
    },
};
