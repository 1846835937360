import { createSelector } from "@reduxjs/toolkit";
import { configService } from "./api";
const getConfigResult = configService.endpoints.getConfig.select();
export const selectConfig = createSelector(getConfigResult, (configResult) => configResult.data);
export const selectRedirectUri = createSelector(selectConfig, (config) => {
    if (!config)
        return undefined;
    const { authorizationUri, oidcClientId, oidcRedirectUri } = config;
    return authorizationUri && oidcClientId
        ? `${authorizationUri}?client_id=${encodeURIComponent(oidcClientId)}&response_type=code&redirect_uri=${encodeURIComponent(oidcRedirectUri)}&scope=${encodeURIComponent("openid profile email address accounts venues dexibit-api iris kharon hades orpheus")}`
        : undefined;
});
export const selectLogoutUri = createSelector(selectConfig, (config) => {
    if (!config)
        return undefined;
    const { logoutUri, baseUri } = config;
    if (!logoutUri && !baseUri) {
        return undefined;
    }
    return `${logoutUri}?post_logout_redirect_uri=${encodeURIComponent(`${baseUri}`)}`;
});
