import { isRejectedWithValue, } from "@reduxjs/toolkit";
import logger from "../utils/logger";
import tokenManager from "../utils/tokenManager";
export const apiErrorHandler = (_api) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (action.payload.status === 403) {
            logger.warn("Unauthorised");
            tokenManager.removeToken();
        }
        if (action.payload.status === 401) {
            logger.warn("Unauthenticated");
            tokenManager.removeToken();
        }
        logger.warn("Network error", action);
    }
    return next(action);
};
