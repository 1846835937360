import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Helmet } from "react-helmet-async";
import { useGetConfigQuery } from "./store/services/config";
// Adds Intercom and Pendo init scripts
export const NavigationHelmet = () => {
    const { data: config } = useGetConfigQuery();
    const appId = config === null || config === void 0 ? void 0 : config.intercomAppId;
    const pendoApiKey = config === null || config === void 0 ? void 0 : config.pendoApiKey;
    if (!appId || !pendoApiKey)
        return null;
    return (_jsxs(Helmet, { children: [_jsx("script", Object.assign({ type: "text/javascript" }, { children: `window.intercomSettings = {
          app_id: "${appId}",
        };` })), _jsx("script", Object.assign({ async: true, type: "text/javascript" }, { children: `(function(){
          var w=window;
          var ic=w.Intercom;
          if(typeof ic==="function"){
            ic('reattach_activator');
            ic('update', w.intercomSettings);
          }else{
            var d=document;
            var i=function(){i.c(arguments);};
            i.q=[];
            i.c=function(args){i.q.push(args);};
            w.Intercom=i;
            var l=function(){
              var s=d.createElement('script');
              s.type='text/javascript';
              s.async=true;
              s.src='https://widget.intercom.io/widget/' + "${appId}";
              var x=d.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s, x);
            };
            if(document.readyState==='complete'){
              l();
            }else if(w.attachEvent){
              w.attachEvent('onload',l);
            }else{
              w.addEventListener('load',l,false);
            }}})();
        ` })), _jsx("script", Object.assign({ async: true, type: "text/javascript" }, { children: `(function (apiKey) {
          (function (p, e, n, d, o) {
            var v, w, x, y, z;
            o = p[d] = p[d] || {};
            o._q = [];
            v = ["initialize", "identify", "updateOptions", "pageLoad"];
            for (w = 0, x = v.length; w < x; ++w)
              (function (m) {
                o[m] =
                  o[m] ||
                  function () {
                    o._q[m === v[0] ? "unshift" : "push"](
                      [m].concat([].slice.call(arguments, 0))
                    );
                  };
              })(v[w]);
            y = e.createElement(n);
            y.async = !0;
            y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
            z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
          })(window, document, "script", "pendo");
        })("${pendoApiKey}");` }))] }));
};
