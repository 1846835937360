import { configureStore } from "@reduxjs/toolkit";
import isDev from "../utils/isDev";
import { apiErrorHandler } from "./middleware";
import { accountService } from "./services/account";
import { authService } from "./services/authentication";
import { configService } from "./services/config";
import { featureFlagReducer } from "./services/featureFlag/reducers";
import { moduleService } from "./services/modules";
import { userService } from "./services/user";
import { userInfoService } from "./services/userInfo";
import { venueService } from "./services/venues";
export const store = configureStore({
    reducer: {
        [configService.reducerPath]: configService.reducer,
        [userInfoService.reducerPath]: userInfoService.reducer,
        [authService.reducerPath]: authService.reducer,
        [moduleService.reducerPath]: moduleService.reducer,
        [userService.reducerPath]: userService.reducer,
        [accountService.reducerPath]: accountService.reducer,
        [venueService.reducerPath]: venueService.reducer,
        featureFlag: featureFlagReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiErrorHandler, authService.middleware, configService.middleware, userInfoService.middleware, moduleService.middleware, userService.middleware, accountService.middleware, venueService.middleware),
    devTools: isDev(),
});
