const MESSAGES_TO_IGNORE = [
    // we not interested in user's network issues
    "Network Error",
    "XHR error GET",
    // we not interested when the user's session has expired
    "Request failed with status code 401",
    // discard some typical react issues
    "Using UNSAFE_componentWillReceiveProps in strict mode",
    "perform a React state update on an unmounted component",
    // Ignore some log lines about LaunchDarkly we do not need to know about
    "LaunchDarkly client initialized",
    "Opening stream connection to",
    "Closing stream connection",
    "Error on stream connection",
    "received streaming update for all flags",
    "polling for feature flags at",
    "sending diagnostic event",
    `enqueueing "feature" event`,
    `enqueueing "identify" event`,
];
function ignoreMessage(message) {
    const ignore = MESSAGES_TO_IGNORE.some((messageToIgnore) => {
        return String(message).toLowerCase().includes(messageToIgnore.toLowerCase());
    });
    return ignore;
}
export default ignoreMessage;
