import { faCalendar, faDatabase, faHouse, faLandmarkDome, faRectangleVerticalHistory, faTelescope, } from "@fortawesome/pro-regular-svg-icons";
import { isUserAdmin } from "../../../common/constants/userRole";
import tokenManager, { TOKEN_KEY } from "../tokenManager";
import { getFromParams } from "../url";
const constructDashboardUrl = (baseUrl, user, allModuleAccess, accountId, token) => {
    var _a;
    const { email, accountName, id } = user;
    const isAdmin = isUserAdmin(user.roles);
    const selectedVenueId = (_a = user.properties) === null || _a === void 0 ? void 0 : _a.selectedVenueId;
    // TODO: Calculate this by feature IS_PAID when it exists
    const isPaidVersion = Boolean(allModuleAccess);
    return `${baseUrl}?headless=true&email=${encodeURIComponent(email)}&accountId=${accountId}&accountName=${encodeURIComponent(accountName || "")}&userId=${id}&venueId=${selectedVenueId}&isPaidVersion=${isPaidVersion}&isAdmin=${isAdmin}&token=${token}`;
};
const constructRoleCheck = (id) => (subModule) => subModule.id === id;
const isDashboard = constructRoleCheck("dashboard");
const isPlatform = constructRoleCheck("platform");
const isDataExplorer = constructRoleCheck("data-explorer");
const addSubModules = (to, tokenParam, contextParams, featureFlags, subModules) => {
    if (!subModules) {
        return undefined;
    }
    // TODO: Consider to extract function within map into a function, with single Params argument.
    // then simple logic can be applied eg. subModule.isVaadin within the map to construct SubLink
    const subLinks = subModules.reduce((subLinks, subModule) => {
        let skip = false;
        if (isPlatform(subModule)) {
            skip = !featureFlags.connectionsCatalogue;
        }
        if (skip) {
            return subLinks;
        }
        // TODO: Consider to either rewrite this with if else to be more clear of contextParams or tokenParam is in use.
        // or lift the decision up stream and purely form the parameter.
        let iFrameUrl = `${subModule.url}${subModule.includeVenueId ? contextParams : ""}`;
        if (isDataExplorer(subModule) || isPlatform(subModule)) {
            iFrameUrl = `${iFrameUrl}${tokenParam}`;
        }
        else if (subModule.isVaadin) {
            const [baseUrl, vaadinPath] = subModule.url.split("#!");
            iFrameUrl = `${baseUrl}${tokenParam}#!${vaadinPath}`;
        }
        subLinks.push({
            title: subModule.name,
            to: `${to}/${subModule.id}`,
            iFrameUrl,
            path: subModule.path,
        });
        return subLinks;
    }, []);
    return subLinks;
};
// TODO: Refactor this so not so hard-coded
// TODO: Consider this to be done either within server side or client side not both (Server and client side),
// as constructing url from both ends creates risk for misformed url.
// TODO: Pass links and required information from server and client can be pure to display it.
export const constructLinks = (featureFlags, moduleData, user, accountId, allModuleAccess, rootPath) => {
    var _a;
    const token = getFromParams(TOKEN_KEY) || tokenManager.getToken();
    const links = [];
    const selectedVenueId = (_a = user === null || user === void 0 ? void 0 : user.properties) === null || _a === void 0 ? void 0 : _a.selectedVenueId;
    // TODO: Consider to use URLSearchParams instead of string format and append to prevent misformed url
    const accessTokenParam = `?access_token=${token}`;
    // TODO: remove headless once insights no longer relies on it
    const contextParams = `?headless=true&venueId=${selectedVenueId}&access_token=${token}`;
    if (!moduleData || !user) {
        return links;
    }
    const constructModuleLink = (module, icon, title, to, featureFlags) => {
        // TODO: Consider to lift addSubModules map operation to this level for more clarity.
        const sublinks = addSubModules(to, accessTokenParam, contextParams, featureFlags, module.subModules);
        // TODO: tidy up this logic to use a better identifier for Dashboard
        let url;
        if (isDashboard(module)) {
            url = constructDashboardUrl(module.url, user, Boolean(allModuleAccess), accountId, token);
        }
        else if (module.isVaadin) {
            const [baseUrl, vaadinPath] = module.url.split("#!");
            url = `${baseUrl}${accessTokenParam}#!${vaadinPath}`;
        }
        else {
            url = `${module.url}${module.includeVenueId ? contextParams : ""}`;
        }
        return {
            to,
            title,
            fullTitle: module.name,
            icon,
            iFrameUrl: url,
            subLinks: sublinks,
        };
    };
    if (moduleData.dashboard) {
        links.push(constructModuleLink(moduleData.dashboard, faHouse, "Home", `${rootPath}/dashboard`, featureFlags));
    }
    if (moduleData.forecasts) {
        links.push(constructModuleLink(moduleData.forecasts, faTelescope, "Forecasts", `${rootPath}/forecasts`, featureFlags));
    }
    if (moduleData.almanac) {
        links.push(constructModuleLink(moduleData.almanac, faCalendar, "Almanac", `${rootPath}/almanac`, featureFlags));
    }
    if (moduleData.legacy) {
        links.push(constructModuleLink(moduleData.legacy, faRectangleVerticalHistory, "Legacy", `${rootPath}/legacy`, featureFlags));
    }
    if (moduleData.data) {
        links.push(constructModuleLink(moduleData.data, faDatabase, "Data", `${rootPath}/data`, featureFlags));
    }
    if (moduleData.administration) {
        links.push(constructModuleLink(moduleData.administration, faLandmarkDome, "Admin", `${rootPath}/administration`, featureFlags));
    }
    return links;
};
