import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../client/DefaultClient";
export const moduleService = createApi({
    reducerPath: "moduleService",
    baseQuery: fetchBaseQuery(baseQuery),
    tagTypes: ["Modules"],
    endpoints: (builder) => ({
        getModules: builder.query({
            query: ({ userId }) => `api/modules/${userId}`,
            providesTags: [{ type: "Modules" }],
        }),
    }),
});
export const { useGetModulesQuery } = moduleService;
