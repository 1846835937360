export const getActiveLink = (links, pathname, rootPath) => {
    // Flatten links and sublinks into flat array then find matching pathname
    const flatLinks = links.flatMap((link) => {
        var _a;
        const sublinks = (_a = link.subLinks) === null || _a === void 0 ? void 0 : _a.map((sublink) => ({
            to: sublink.to,
            fullTitle: sublink.title,
            icon: link.icon,
        }));
        return [
            {
                to: link.to,
                fullTitle: link.fullTitle,
                icon: link.icon,
            },
            ...(sublinks || []),
        ];
    });
    // If at root, then select first link
    if (pathname === `${rootPath}/`) {
        return flatLinks[0];
    }
    return flatLinks.find((link) => link.to === pathname);
};
