import { useAuthListener } from "./useAuthListener";
import { useDialogListener } from "./useDialogListener";
import { useVenueSelectionListener } from "./useVenueSelectionListener";
// Listen for messages from iFrame applications
export const useAppListeners = (user, config) => {
    useAuthListener(config);
    useVenueSelectionListener(user);
    const { dialogVisible } = useDialogListener();
    return { dialogVisible };
};
