import tokenManager from "../../utils/tokenManager";
// Might be able to get away with one baseQuery and change baseUrl dynamically. Look into later
export const baseQuery = {
    baseUrl: "/v2",
    prepareHeaders: (headers, { endpoint }) => {
        const jwt = tokenManager.getToken();
        if (jwt && endpoint !== "getConfig") {
            headers.set("authorization", `Bearer ${jwt}`);
        }
        headers.set("content-type", "application/json");
        return headers;
    },
};
