export var RoleDTO;
(function (RoleDTO) {
    RoleDTO["SYSTEM_ADMIN"] = "SYSTEM_ADMIN";
    RoleDTO["ACCOUNT_ADMIN"] = "ACCOUNT_ADMIN";
    RoleDTO["USER"] = "USER";
    RoleDTO["SUPPORT"] = "SUPPORT";
})(RoleDTO || (RoleDTO = {}));
export const isUserAdmin = (roles) => {
    return (roles.includes(RoleDTO.ACCOUNT_ADMIN) ||
        roles.includes(RoleDTO.SYSTEM_ADMIN) ||
        roles.includes(RoleDTO.SUPPORT));
};
export const isUserSystemAdmin = (roles) => {
    return roles.includes(RoleDTO.SYSTEM_ADMIN);
};
