import { useEffect } from "react";
import { useGetConfigQuery } from "../store/services/config";
import { intercomHandler } from "../utils/intercom";
export const useIntercom = (user, venue, account) => {
    const { data: config } = useGetConfigQuery();
    const appId = config === null || config === void 0 ? void 0 : config.intercomAppId;
    useEffect(() => {
        var _a, _b, _c, _d;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (user && account && appId && window.Intercom) {
            const fullName = `${(_a = user.properties) === null || _a === void 0 ? void 0 : _a.firstName} ${(_b = user.properties) === null || _b === void 0 ? void 0 : _b.lastName}`;
            // To be consistent with current implementation. Not exactly correct but fine for now
            const accountType = ((_c = account.properties) === null || _c === void 0 ? void 0 : _c.allModuleAccess)
                ? "Premium"
                : "Free/Classic";
            window.Intercom("boot", {
                app_id: appId,
                email: user.email,
                user_id: user.id.toString(),
                name: fullName,
                dexibit_account_id: account.id,
                dexibit_account_name: account.name,
                dexibit_venue_id: venue === null || venue === void 0 ? void 0 : venue.id,
                dexibit_venue_name: venue === null || venue === void 0 ? void 0 : venue.name,
                dexibit_user_id: user.id,
                dexibit_user_full_name: fullName,
                dexibit_user_first_name: (_d = user.properties) === null || _d === void 0 ? void 0 : _d.firstName,
                dexibit_email: user.email,
                dexibit_role: user.roles[0],
                dexibit_account_type: accountType,
                dexibit_access_enabled: user.enabled,
                // Can add company info here using account data
                // Must wait for new Intercom instance
            });
            intercomHandler.updateIntercomInterval();
        }
    }, [user, venue, account, appId]);
};
