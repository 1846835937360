import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useLocation } from "react-router-dom";
import { StyledIframe } from "../styles";
const IFramePage = ({ id, url }) => {
    const state = useLocation().state;
    let fullUrl = (state === null || state === void 0 ? void 0 : state.iFrameUrl) ? state.iFrameUrl : url;
    // Handles special routing case for edit profile
    if (id === "Admin") {
        fullUrl = `${url}#${state === null || state === void 0 ? void 0 : state.path}${state === null || state === void 0 ? void 0 : state.id}`;
    }
    return _jsx(StyledIframe, { title: id, id: id, src: fullUrl, allow: "clipboard-write" });
};
export default IFramePage;
