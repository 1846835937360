import { skipToken } from "@reduxjs/toolkit/query";
import { useGetAccountQuery } from "../store/services/account/api";
import { useGetUserQuery } from "../store/services/user";
import { useGetVenueQuery } from "../store/services/venues";
import tokenManager from "../utils/tokenManager";
export const useUserContext = () => {
    var _a;
    const decodedToken = tokenManager.getDecodedToken();
    const userId = decodedToken === null || decodedToken === void 0 ? void 0 : decodedToken.sub;
    const accountId = decodedToken === null || decodedToken === void 0 ? void 0 : decodedToken.aid;
    // Get User
    const { data: user, isError: isUserError, isLoading: isUserLoading, } = useGetUserQuery(Boolean(decodedToken) && userId !== undefined ? { id: +userId } : skipToken);
    const selectedVenueId = (_a = user === null || user === void 0 ? void 0 : user.properties) === null || _a === void 0 ? void 0 : _a.selectedVenueId;
    // Get Account
    const { data: account, isError: isAccountError, isLoading: isAccountLoading, } = useGetAccountQuery(Boolean(decodedToken) && accountId !== undefined ? { id: accountId } : skipToken);
    // Get Selected Venue
    const { data: venue, isError: isVenueError, isLoading: isVenueLoading, } = useGetVenueQuery(selectedVenueId !== undefined ? { id: selectedVenueId } : skipToken);
    return {
        user,
        account,
        venue,
        isUserContextError: isUserError || isAccountError || isVenueError,
        isUserContextLoading: isUserLoading || isAccountLoading || isVenueLoading,
    };
};
