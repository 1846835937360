import { useCallback, useEffect } from "react";
import { useAppSelector } from "../store";
import { useLazyCheckAuthenticationQuery } from "../store/services/authentication";
import { selectRedirectUri } from "../store/services/config";
import { navigationChannel, USER_UNAUTHENTICATED } from "../utils/broadcastChannel";
// Listen for iFrame app to tell navigation that token is no longer valid
// Check token again when message received and redirect to login if invalid
export const useAuthListener = (config) => {
    const verifyTokenUri = config === null || config === void 0 ? void 0 : config.verifyTokenUri;
    const redirectUri = useAppSelector(selectRedirectUri);
    const [checkAuth, checkAuthResult] = useLazyCheckAuthenticationQuery();
    // TODO: Remove this once all apps are using broadcast channel
    const sendMessage = useCallback((event) => {
        if (event.origin.includes("dexibit.io") || event.origin.includes("dexibit.com")) {
            const message = event.data;
            if (message === USER_UNAUTHENTICATED && verifyTokenUri) {
                void checkAuth({ verifyTokenUri });
            }
        }
    }, [checkAuth, verifyTokenUri]);
    useEffect(() => {
        if (verifyTokenUri) {
            navigationChannel.addEventListener("message", (event) => {
                if (event.data === USER_UNAUTHENTICATED) {
                    void checkAuth({ verifyTokenUri });
                }
            });
            window.addEventListener("message", sendMessage);
        }
        return () => {
            window.removeEventListener("message", sendMessage);
        };
    }, [verifyTokenUri, checkAuth, sendMessage]);
    useEffect(() => {
        const { isUninitialized, isFetching, isError } = checkAuthResult;
        if (!isUninitialized && !isFetching && isError && redirectUri) {
            window.location.href = redirectUri;
        }
    }, [checkAuthResult, redirectUri]);
};
