// remember this is not the same as NODE_ENV!
export var Environ;
(function (Environ) {
    // special case for local development only
    Environ["LOCAL"] = "local";
    // special case for JEST unit tests only
    Environ["TEST"] = "test";
    // these below are set in ansible only
    Environ["DEV"] = "dev";
    Environ["STAGING"] = "staging";
    Environ["PROD"] = "prod";
})(Environ || (Environ = {}));
// and these actually define the runtime mode of node.js and are
// set either in package.json, via Jest or in the Dockerfile
export var NodeEnvType;
(function (NodeEnvType) {
    NodeEnvType["PRODUCTION"] = "production";
    NodeEnvType["DEVELOPMENT"] = "development";
    NodeEnvType["TEST"] = "test";
})(NodeEnvType || (NodeEnvType = {}));
