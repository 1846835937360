import { FONT, PALETTE_LIGHT, styled } from "@dexibit/styles";
export const AppContainer = styled("div") `
  font-family: ${FONT.BODY_REGULAR.fontFamily};
  font-size: ${FONT.BODY_REGULAR.fontSize};
  color: ${PALETTE_LIGHT.TEXT_EMPHASIS_REGULAR};
  width: 100%;
  min-height: 100vh;
  display: flex;
`;
export const AppContent = styled("section") `
  background-color: ${PALETTE_LIGHT.SURFACE_TEXTURE};
  flex: 1;
  margin-top: 1px;
  display: flex;
`;
export const LoadingContainer = styled("div") `
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const MainContent = styled("div") `
  flex: 1;
`;
export const StyledIframe = styled("iframe") `
  width: 100%;
  height: calc(100vh - 57px);
  border: none;
`;
export const Styled404Container = styled("div") `
  width: 100%;
  height: calc(100vh - 57px);
  border: none;
`;
