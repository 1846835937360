import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import getEnv from "../getEnv";
import isProd from "../isProd";
import isTest from "../isTest";
import ignoreMessage from "./ignoreMessage";
const APPLICATION_ID = "64913c25-f3e9-457e-8e56-b212da04b87d";
const CLIENT_TOKEN = "pub64031eec811d4f31e4a911757f4a6cd1";
const SERVICE = "navigation";
const initDatadog = () => {
    if (isTest())
        return;
    // Let's record them on prod only
    const enableSessionReplay = isProd();
    const rumInitConfiguration = {
        applicationId: APPLICATION_ID,
        clientToken: CLIENT_TOKEN,
        env: getEnv(),
        service: SERVICE,
        sessionSampleRate: 100,
        trackUserInteractions: true,
        useCrossSiteSessionCookie: true,
        // See https://docs.datadoghq.com/real_user_monitoring/session_replay/#disable-session-replay
        sessionReplaySampleRate: enableSessionReplay ? 100 : 0,
    };
    datadogRum.init(rumInitConfiguration);
    if (enableSessionReplay) {
        datadogRum.startSessionReplayRecording();
    }
    const logsInitConfiguration = {
        clientToken: CLIENT_TOKEN,
        env: getEnv(),
        service: SERVICE,
        beforeSend: (log) => {
            var _a, _b;
            // discard ad blockers causing those errors
            if (((_a = log.http) === null || _a === void 0 ? void 0 : _a.status_code) === 404 || ((_b = log.http) === null || _b === void 0 ? void 0 : _b.status_code) === 0) {
                return false;
            }
            const ignore = ignoreMessage(log.message);
            if (ignore) {
                return false;
            }
            return true;
        },
    };
    datadogLogs.init(logsInitConfiguration);
};
export default initDatadog;
