import { jwtDecode } from "jwt-decode";
export const TOKEN_KEY = "access_token";
class TokenManager {
    constructor() {
        this.token = null;
    }
    getToken() {
        return this.token;
    }
    setToken(token) {
        this.token = token;
    }
    removeToken() {
        this.token = null;
    }
    getDecodedToken() {
        const token = this.getToken();
        if (!token)
            return undefined;
        try {
            return jwtDecode(token);
        }
        catch (exc) {
            // Silently remove any invalid token
            this.removeToken();
            return undefined;
        }
    }
    getUserData() {
        const decodedToken = this.getDecodedToken();
        if (!decodedToken)
            return undefined;
        const userId = parseInt(decodedToken.sub);
        const userEmail = decodedToken.upn;
        const userData = {
            id: userId,
            email: userEmail,
            accountId: parseInt(decodedToken.aid),
            roles: decodedToken.groups,
        };
        return userData;
    }
    isImpersonationMode() {
        const decodedToken = this.getDecodedToken();
        return Boolean(decodedToken === null || decodedToken === void 0 ? void 0 : decodedToken.act);
    }
}
const tokenManager = new TokenManager();
export default tokenManager;
