import { useCallback, useEffect, useState } from "react";
import { useGetUserQuery } from "../store/services/user";
import { navigationChannel, VENUE_CHANGED } from "../utils/broadcastChannel";
// When loaded venue is changed in Identity Management iFrame
// Trigger a reload of the current user record to then pass the correct venueId to other iFrame apps
export const useVenueSelectionListener = (user) => {
    const userId = user === null || user === void 0 ? void 0 : user.id;
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const { refetch } = useGetUserQuery({
        // TODO Please correct typings soon
        // @ts-ignore
        id: userId,
    }, { skip: !userId });
    navigationChannel.addEventListener("message", (event) => {
        if (event.data === VENUE_CHANGED) {
            setTriggerRefetch(true);
        }
    });
    const refetchUser = useCallback(() => {
        void refetch();
    }, [refetch]);
    useEffect(() => {
        if (triggerRefetch) {
            refetchUser();
            setTriggerRefetch(false);
        }
    }, [triggerRefetch, refetchUser]);
};
