import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes } from "react-router-dom";
import { Loader } from "@dexibit/core";
import ErrorFallback from "./components/ErrorFallback";
import Header from "./components/Header";
import { Navigation } from "./components/Navigation";
import { useAppListeners, useInit, useIntercom, useModules, usePendo, useUserContext, } from "./hooks";
import useLaunchDarkly from "./hooks/useLaunchDarkly";
import FourOhFourPage from "./pages/FourOhFourPage";
import IFramePage from "./pages/IFramePage";
import { AppContainer, AppContent, LoadingContainer, MainContent } from "./styles";
import logger from "./utils/logger";
const App = () => {
    var _a, _b;
    const { authenticated, userInfo, isInitError, isInitLoading, isUninitialized, config } = useInit();
    const { user, account, venue, isUserContextError, isUserContextLoading } = useUserContext();
    const { links, activeLink, isModulesError, hasLinks } = useModules(user, account);
    useLaunchDarkly(user);
    useIntercom(user, venue, account);
    usePendo(user, venue);
    const { dialogVisible } = useAppListeners(user, config);
    const appIsLoading = isUninitialized ||
        isInitLoading ||
        !authenticated ||
        !hasLinks ||
        isUserContextLoading;
    const appIsError = isInitError || isModulesError || isUserContextError;
    if (appIsError) {
        return (_jsx(AppContainer, { children: _jsx(ErrorFallback, {}) }));
    }
    if (appIsLoading) {
        return (_jsx(AppContainer, { children: _jsx(LoadingContainer, { children: _jsx(Loader, { size: "large" }) }) }));
    }
    return (_jsxs(AppContainer, { children: [_jsx(Navigation, { links: links, activeLink: activeLink, dialogVisible: dialogVisible }), _jsxs(MainContent, { children: [_jsx(Header, { config: config, userInfo: userInfo, accountName: account === null || account === void 0 ? void 0 : account.name, venueName: venue === null || venue === void 0 ? void 0 : venue.name, activeLink: activeLink, dialogVisible: dialogVisible }), _jsx(ErrorBoundary, Object.assign({ FallbackComponent: ErrorFallback, onError: (error) => logger.error("Application error", error) }, { children: _jsx(AppContent, { children: _jsxs(Routes, { children: [_jsx(Route, { path: `${config === null || config === void 0 ? void 0 : config.rootPath}/`, element: _jsx(IFramePage, { id: (_a = links[0]) === null || _a === void 0 ? void 0 : _a.title, url: (_b = links[0]) === null || _b === void 0 ? void 0 : _b.iFrameUrl }) }), links.map((link) => {
                                        return (_jsx(Route, { path: `${link.to}/*`, element: _jsx(IFramePage, { id: link.title, url: link.iFrameUrl }) }, link.title));
                                    }), _jsx(Route, { path: "*", element: _jsx(FourOhFourPage, {}) })] }) }) }))] })] }));
};
export default App;
