import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../client/DefaultClient";
// Fetches the base config for the service
// This may be different depending on your projects backend setup
// This config includes redirect urls for auth etc
export const configService = createApi({
    reducerPath: "configService",
    baseQuery: fetchBaseQuery(baseQuery),
    tagTypes: ["Config"],
    endpoints: (builder) => ({
        getConfig: builder.query({
            query: () => `api/config`,
            providesTags: [{ type: "Config", id: "LIST" }],
        }),
    }),
});
export const { useGetConfigQuery } = configService;
