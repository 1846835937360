import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppSelector } from "../store";
import { useCheckAuthenticationQuery } from "../store/services/authentication";
import { selectRedirectUri, useGetConfigQuery } from "../store/services/config";
import { useGetUserInfoQuery } from "../store/services/userInfo";
import tokenManager, { TOKEN_KEY } from "../utils/tokenManager";
import { getFromParams } from "../utils/url";
// Hook which handles app initialisation.
// First fetches config, then checks if user is authenticated
// If user is authenticated then fetch userinfo, otherwise redirect to login
export const useInit = () => {
    // Fetch token from params if exists otherwise fetch from localstorage
    const storedToken = tokenManager.getToken();
    const token = getFromParams(TOKEN_KEY) || storedToken;
    // Set latest token to local storage
    useEffect(() => {
        if (!token)
            return;
        tokenManager.setToken(token);
    }, [token]);
    // Fetch config
    const { data: config, isError: isConfigError, isUninitialized: isConfigUninitialized, } = useGetConfigQuery();
    const oidcClientId = config === null || config === void 0 ? void 0 : config.oidcClientId;
    const redirectUri = useAppSelector(selectRedirectUri);
    // Check token valid
    const { data: authenticated, isError: isAuthError, isLoading: isAuthLoading, } = useCheckAuthenticationQuery(oidcClientId
        ? {
            verifyTokenUri: config.verifyTokenUri,
        }
        : // https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#skiptoken
            skipToken);
    // Fetch user info if authenticated and no auth error
    const { data: userInfo, isError: isUserInfoError, isLoading: isUserInfoLoading, } = useGetUserInfoQuery(authenticated && !isAuthError && config
        ? { userInfoUri: config.userInfoUri }
        : // https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#skiptoken
            skipToken);
    // If not authenticated then redirect to login
    useEffect(() => {
        if (!isAuthLoading && !authenticated && redirectUri) {
            window.location.href = redirectUri;
        }
    }, [isAuthLoading, authenticated, redirectUri]);
    return {
        config,
        userInfo,
        authenticated,
        isInitError: isConfigError || isUserInfoError,
        isInitLoading: isAuthLoading || isUserInfoLoading,
        isUninitialized: isConfigUninitialized,
    };
};
