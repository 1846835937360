import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../client/DefaultClient";
export const venueService = createApi({
    reducerPath: "venueService",
    baseQuery: fetchBaseQuery(baseQuery),
    tagTypes: ["Venue"],
    endpoints: (builder) => ({
        getVenue: builder.query({
            query: ({ id }) => `api/identity/venues/${id}`,
            providesTags: (venue) => [{ type: "Venue", id: venue === null || venue === void 0 ? void 0 : venue.id }],
        }),
    }),
});
export const { useGetVenueQuery } = venueService;
